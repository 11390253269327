import React from 'react'

export default function ReadyToGoCard({ header, text, children }) {
  return (
    <div className="ready-to-go-box">
      <h2 className="medium-heading-2">{header}</h2>
      {text && <p>{text}</p>}
      {!text && children}
    </div>
  )
}
