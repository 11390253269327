import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/Hero'
import PatientBehaviorCard from '../components/PatientBehaviorCard'
import CallToAction from '../components/CallToAction'
import QuoteBox from '../components/QuoteBox'
import { getLinkToExternalApp } from '../utils/api'
import { FaChevronDown } from 'react-icons/fa'
import Video from '../components/Video'
import ReadyToGoCard from '../components/ReadyToGoCard'
import iconData from '../images/icon-data.svg'
import iconText from '../images/icon-text.svg'
import iconAlert from '../images/icon-alert.svg'

export default function channelPartnersPage() {
  return (
    <main className="biopharma-page">
      <Hero color="blue" image="data-devices" heading="The in-home partner every medication should have">
        <p>
          An elegantly simple and proven system designed to gently remind patients to take their medications and
          intervene when they don’t — early enough to make a difference. Making sure not a day is lost on their path to
          better health.
        </p>
      </Hero>
      <div className="page-section">
        <div className="page-section-content">
          <p>
            AdhereTech works with leading pharmaceutical companies and clinical trial teams to foster optimal adherence
            with reliable data to support data-driven interventions when patients need help to continue on therapy.
          </p>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content audiences">
          <a className="pharma" href="#pharma-programs">
            Pharma programs
            <FaChevronDown className="chevron-down" />
          </a>
          <a className="clinical" to="/clinical-trials" href="#clinical-trials">
            Clinical trials
            <FaChevronDown className="chevron-down" />
          </a>
        </div>
      </div>
      <div className="page-section">
        <div id="pharma-programs"></div>
        <div className="page-section-content pharma-programs">
          <h1>Pharma programs</h1>
        </div>
        <div className="page-section-content">
          <h2>Partnership across the pharma development and commercialization lifecycle</h2>
        </div>
        <div className="page-section-content">
          <p className="final-p">
            AdhereTech works closely with leading pharma companies to support clinical development of a medicine, and
            once approved, works to support optimal patient adherence throughout the medicine’s lifecycle, from launch
            through LoE.
          </p>
        </div>
        <div className="page-section-content">
          <StaticImage
            src="../images/behavioral-intelligence.jpg"
            alt="Behavioral Intelligence"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>

      <div className="page-section--blue challenging-environment">
        <div className="page-section-content">
          <h2>Increasingly challenging environment</h2>
        </div>
        <div className="page-section-content">
          <p className="final-p">
            Despite increased investment in supporting access to new medicines, prescription fulfillment rates are down
            54% between 2015-2021 with only 25% of newly launched prescribed medicines being fulfilled in the commercial
            channel.
          </p>
        </div>
        <div className="page-section-content">
          <StaticImage
            src="../images/new-patient-utilization-management.png"
            alt="New Patient Utilization Management"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>

      <div className="page-section business-model">
        <h2>Business model</h2>
        <h2 className="sub-heading">
          High patient opt-in rate allows platform to collect patient data and provide timely interventions
        </h2>
        <p>
          Pharma engages AdhereTech and we integrate Aidia into the existing specialty medicine distribution network.
          This integration allows for personalized, real-time behavioral intervention and support for patients — at zero
          cost to them. By working directly with specialty pharmacies, AdhereTech is also able to escalate clinical
          issues to patient care teams, ensuring that patients receive support when they need it the most.
        </p>
        <div className="ready-to-go-grid">
          <ReadyToGoCard
            header="Deep integration"
            text="AdhereTech system and data is integrated into established workflows."
          />
          <ReadyToGoCard
            header="Easy onboarding"
            text="Simple, patient-friendly onboarding process with high net implementation rate > 50%."
          />
          <ReadyToGoCard
            header="Thoughtful design"
            text="Designed to optimize patient experience and care team workflows."
          />
        </div>
      </div>

      <div className="page-section--blue partner-portal">
        <div className="page-section-content">
          <div className="partner-portal-text">
            <h2>Partner portal gives visibility into patient behavior</h2>
            <h2 className="sub-heading">24/7 dashboard focuses care resources where needed, when needed</h2>
            <ul>
              <li>Actionable analytics</li>
              <li>Patient dose tracking</li>
              <li>Patient communications</li>
              <li>Adherence reporting and charts</li>
              <li>Timely alerts optimizing care team resources</li>
            </ul>
          </div>
          <div>
            <StaticImage
              src="../images/calendar-view.png"
              alt="Partner portal calendar view"
              placeholder="none"
              layout="constrained"
              height={500}
            />
          </div>
        </div>
      </div>

      <div className="page-section">
        <div className="page-section-content">
          <h2>Customizable program solutions</h2>
        </div>
        <div className="page-section-content">
          <p>
            We work with our Pharma partners to develop treatment-specific motivational and achievement messaging to
            engage patients and reinforce positive experiences, optimizing use of programmable data to ensure
            interventions maximize efficiency and impact.
          </p>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid contact-us">
          <CallToAction
            headerText="Partner with us"
            content="Speak with a representative about how we can work with you to optimally support your patients’ medication experience."
            linkText="Contact Us"
            linkUrl="/contact-us?organizationType=pharma"
            color="blue"
          />
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content" id="clinical-trials"></div>
        <div className="page-section-content clinical-trials">
          <h1>Clinical trials</h1>
        </div>
        <div className="page-section-content">
          <h2>Protocol non-compliance is a key risk to study outcomes</h2>
        </div>
        <div className="page-section-content">
          <p>
            Patient non-compliance with a study protocol is a risk to study outcomes. However, studies suffer from a
            lack of visibility into whether a patient has followed the study protocol. Investigational product exposures
            &lt;80% result in a failed study. On the flip side, sponsors have no visibility into whether a patient has
            taken more investigational product than intended, thereby increasing the risk of toxicity. Both situations
            put the study outcome at risk.
            <br />
            <br />
            It’s estimated that 40% of clinical trial patients become non-adherent with study protocols in less than 5
            months. (<em>Source:</em>{' '}
            <a
              href="https://www.appliedclinicaltrialsonline.com/view/non-adherence-direct-influence-clinical-trial-duration-and-cost"
              target="_blank"
              rel="noopener noreferrer"
            >
              Non-Adherence: A Direct Influence on Clinical Trial Duration and Cost
            </a>
            )
            <br />
            <br />
            Unfortunately, existing protocol compliance solutions add to, rather than reduce, patient burden — as they
            require patients to download apps, connect via wifi, etc.— which limits their use and increases the risk of
            low quality, incomplete data, undermining study integrity.
            <br />
            <br />
            Separately, there is a drive to increase diversity in clinical trials. However, long standing health equity
            issues result in medication non-adherence being an even greater issue in minority populations. (
            <em>Source:</em>{' '}
            <a
              href="https://www.phrma.org/resource-center/Topics/Equity/Health-Inequities-Impede-Access-to-Medicines"
              target="_blank"
              rel="noopener noreferrer"
            >
              Health Inequities Impede Access to Medicines
            </a>
            )
            <br />
            <br />
            AdhereTech works with leading pharmaceutical companies, clinical research organizations (CROs) and academic
            institutions to optimize their clinical study protocol adherence, resulting in greater patient retention,
            reduced costs, improved data integrity and more reliable study outcomes.
          </p>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content">
          <h2>Aidia significantly improves protocol compliance and study efficiency</h2>
        </div>
        <div className="page-section-content real-time">
          <p>
            AdhereTech’s solution, The Aidia System™, monitors and collects patient medication taking behavior in
            real-time via a cellular connection with no patient setup or need for apps. Aidia uses always-on cellular,
            minimizing infrastructure needed to work internationally and even works in areas of low cellular coverage,
            storing results until a signal is available again.
            <br />
            <br />
            Aidia helps minimize the burden on study sites by providing customizable, real-time interventions and
            escalations to help patients stay compliant with the study protocol, reducing the need for additional
            recruitment, as well as administrative costs and risks to study outcomes. Aidia collects and aggregates
            patient medication taking data in real-time which is then consolidated to support regulatory submissions and
            generate unique patient insights informing future studies and launch planning.
          </p>
        </div>
      </div>
      <div className="page-section--blue">
        <div className="page-section-content patient-behavior-cards patient-behavior-cards--long">
          <PatientBehaviorCard
            image={iconAlert}
            altText="icon text"
            content="Aidia validates number of doses taken in real time, identifying late or missed doses immediately for remediation of protocol deviations. If patient medication-taking behavior begins to deviate, Aidia provides gentle real time reinforcement to help patients get back on track."
          />
          <PatientBehaviorCard
            image={iconText}
            altText="icon text"
            content="If protocol non-compliance continues, Aidia tailors its approach to gradually escalate interventions via optional 2-way messaging connecting patients and site staff to easily identify and resolve issues."
          />
          <PatientBehaviorCard
            image={iconData}
            altText="icon bottle smile"
            content="Aidia provides seamless access to in-depth dosing compliance data & customized reporting, facilitates interim & adjunct analyses supporting regulatory submissions, and generates unique patient-level insights."
          />
        </div>
      </div>
      <div className="page-section">
        <QuoteBox
          text="Our trial patients found the technology easy to use.  We were impressed by the detailed data collected and functionality of the AdhereTech reporting. It allowed us to identify adherence risks at the subject level on a daily basis, resulting in quick remediation."
          speaker="Jim M., VP Clinical Operations & Data Management from a pharmaceutical company"
          color="blue"
        />
      </div>
      <div className="page-section">
        <div className="page-section-content simple-right">
          <h2>See how the Aidia System works in clinical trials.</h2>
          <Video
            videoSrcURL="https://www.youtube.com/embed/X03e0Gt-WpE"
            videoTitle="AdhereTech Smart Bottle System for Clinical Trials"
          />
        </div>
      </div>
      <div className="page-section">
        <div className="call-to-action-grid">
          <CallToAction
            headerText="Partner with us"
            content="Speak with a representative about how we can help with your clinical trial."
            linkText="Contact us"
            linkUrl="/contact-us?organizationType=clinical_trials"
            color="blue"
          />
          <CallToAction
            headerText="Already a clinical trial partner?"
            content="Log in to our Partner Portal to access your account."
            linkText="Partner log in"
            linkUrl={getLinkToExternalApp('app')}
            color="blue"
            external
          />
        </div>
      </div>
    </main>
  )
}
